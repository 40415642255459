<template></template>

<script>
import localStorageManager from "../services/localStorageManager";

export default {
  mounted() {
    this.$store.commit("setWebView", true);
    this.$store.commit("setWebViewApp", true);
    const activeuser = localStorageManager.userAuth.getUser();

    if (activeuser) {
      this.$router.push("/start");
    } else {
      this.$router.push("/anmeldung");
    }
  },
};
</script>

<style>
</style>